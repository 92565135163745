<template>
	<div class="container-fluid h-100 my-2">
		<div class="row justify-content-center align-items-center h-100">
			<div class="col-auto licence_choice">
				<router-link :to="{ name: 'Home' }" class="d-block mb-3"><font-awesome-icon class="mr-1" :icon="['fal', 'arrow-left']"/> {{ $t('global.retour') }}</router-link>
				<div class="box" id="login">

					<div class="text-center"><groomy-logo /></div>

					<div class="text-center mt-4 mb-5">
						<h2 class="mt-0 mb-1">{{ $t('compte.switch_licence.select_licence') }}</h2>
						<i>{{ $t('compte.switch_licence.body') }}</i>
					</div>

					<ul>
						<li v-for="(licence, index) in filtredLicences" 
							:key="'licence_'+index"
							@click="chooseLicence(licence)"
							class="licence_choice_card d-flex align-items-center"
							:class="{ disabled: !licence.is_main && is_unvalid }"
						>
							<span>
								<b>{{ licence.label }}</b>
								<br/>
								<i>{{ $t('compte.switch_licence.text') }}</i>
							</span> 
							<font-awesome-icon class="ml-auto" :icon="['fal', 'angle-right']"/>
						</li>
					</ul>


					<div v-if="verrouLoading">
						<p class="text-center"><font-awesome-icon :icon="['fal', 'spinner']" pulse/><br/>{{ $t('global.chargement') }} </p>
					</div>
					
					<div class="text-center mt-4 mb-5">
						<router-view></router-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import UserMixin from '@/mixins/User.js';
	import Navigation from '@/mixins/Navigation.js';

	export default {
		name: "LicenceSwitch",
		mixins: [UserMixin, Navigation],
		data () {
			return {
				licences_raw: [],
				main_licence: null,
                redirect_post_choice: '',
                is_working: false,
				licence_filter: '',
				is_unvalid: false, 
			}
		},
		mounted: async function() {
			this.init_component()
		},
		methods: {
            async init_component() {
				this.redirect_post_choice = this.$route.query.redirect
				this.licences_raw = await this.getAllLicence()
				this.main_licence = this.licences_raw[0]
            },
            async chooseLicence(licence) {
				const licence_key  = licence.key
				const licence_name = licence.username
				const main_licence_key = this.main_licence.licence_key

				if(!licence.is_main && !this.checkLicencePermissiveSwitch(licence)) {
                    return this.$router.push({ name: 'CheckEmailLS', params: { is_licence_switch: true, temp_licence: main_licence_key }})
				}

				this.is_working = true

				// Juste après le login on est pas log donc on a pas besoin de delog
				if (this.$storage.db) {
					await this.changeDb()
				}

                await this.persistUserLicenceKey(licence_key)
                await this.persistUserLicenceName(licence_name)

                // On charge le localstorage de la licence choisie
				this.setUserLoggedIn()

                this.redirectPostLogin(this.redirect_post_choice)
                this.is_working = false
			},
			checkLicencePermissiveSwitch(licence) {
				const valid_mail = this.getConfig("valid_mail")

				// Si le switch de licence se fait sur une licence autre que la principale, on regarde si l'email a été validé
				if(!licence.is_main && !valid_mail) {
					this.is_unvalid = true
					return false
				} 
				return true
			}
		},
		computed: {
            licences() {
            	if(this.licences_raw.length == 0) return []

                let temp = []

                this.licences_raw.forEach(licence => {
					temp.push({
                        key     : licence.licence_key,
                        username: licence.licence_username,
						label   : licence.licence_name,
						is_main : licence.licence_username == this.main_licence.licence_username
                    })
                })

                return temp
			},
            filtredLicences() {
                if(!this.licence_filter) {
                    return this.licences
                }
                return this.licences.filter(licence => licence.label.toLowerCase().includes(this.licence_filter.toLowerCase())) 
            },
			verrouLoading() {
                return this.is_working
			}
		}
	}
</script>
